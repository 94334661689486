import _ from 'lodash';
import AppService from '../shared/App.api';
import LoggingService from '../shared/Logging.api';

export default {
  signOutPingFed,
  getWalmartUser,
  getUsers,
  getUser,
  updateUser,
  transformWalmartUser,
};

function getUsers(userSearchParam, offset = 0) {
  const path = `/admin/users?search=${userSearchParam}&offset=${offset}&limit=25`;
  return AppService.get(path);
}

function getUser(userId) {
  const path = `/admin/users/${userId}`;
  return AppService.get(path);
}

async function updateUser(userId, user) {
  const path = `/admin/users/${userId}`;
  const response = await AppService.patch(path, user);
  return response.data;
}

async function getWalmartUser(tokens) {
  // Exchange the token for the wm user data
  try {
    const path = '/auth/login/adminSSO';
    const result = await AppService.postJson(path, tokens);
    const wmUser = _.get(result, 'data.wm_user', null);

    return transformWalmartUser(wmUser);
  } catch (error) {
    LoggingService.error('getUser', error);
    throw error;
  }
}

function transformWalmartUser(wmUser) {
  return {
    userId: wmUser.sAMAccountName,
    additional: {
      win:
        wmUser['wm-IdentificationNumber'] &&
        wmUser['wm-IdentificationNumber'] !== 'NOT_FOUND'
          ? wmUser['wm-IdentificationNumber']
          : null,
      displayName:
        wmUser.displayNamePrintable &&
        wmUser.displayNamePrintable !== 'NOT_FOUND'
          ? wmUser.displayNamePrintable
          : wmUser.displayName,
      mailId: wmUser.mail,
      phoneNumber:
        wmUser.telephoneNumber && wmUser.telephoneNumber !== 'NOT_FOUND'
          ? wmUser.telephoneNumber
          : wmUser.phoneNumber,
      hireDate:
        wmUser['wm-HireDate'] && wmUser['wm-HireDate'] !== 'NOT_FOUND'
          ? wmUser['wm-HireDate']
          : null,
      jobTitle: wmUser.title,
    },
  };
}

async function signOutPingFed() {
  try {
    const path = '/auth/logout/adminSSO';

    return AppService.postJson(path);
  } catch (error) {
    LoggingService.error('signOutPingFed', error);
    return error;
  }
}
