/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  ArrowDownward,
  Clear,
  FilterList,
  PauseCircleOutline,
  PlayCircleOutline,
  Search,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  SaveAlt,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import colors from '../colors.styles';

const { green, dark_green, red, dark_yellow } = colors.base;

export default function StoresTable(props) {
  const [selectedStores, setSelectedStores] = useState([]);
  const { stores, pauseStores, unpauseStores } = props;

  const commonCellStyle = {
    padding: '0px 10px',
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: green,
      },
      secondary: {
        main: dark_yellow,
      },
    },
  });

  const columns = [
    {
      title: 'Store Number',
      field: 'store_number',
      cellStyle: { ...commonCellStyle, textAlign: 'center' },
      render: ({ store_number }) => (
        <Link to={`/stores/${store_number}`}>{store_number}</Link>
      ),
    },
    { title: 'Region', field: 'region', cellStyle: commonCellStyle },
    { title: 'Market', field: 'marketNumber', cellStyle: commonCellStyle },
    { title: 'State', field: 'state', cellStyle: commonCellStyle },
    { title: 'City', field: 'city', cellStyle: commonCellStyle },
    { title: 'Street', field: 'street', cellStyle: commonCellStyle },
    { title: 'Zip', field: 'zip', cellStyle: commonCellStyle },
    {
      title: 'Paused Status',
      field: 'is_paused',
      lookup: { true: 'Paused', false: 'Live' },
      cellStyle: (rowText, rowData) => ({
        ...commonCellStyle,
        textAlign: 'center',
        fontWeight: 'bold',
        color: rowData.is_paused ? red : dark_green,
      }),
    },
  ];

  const tableIcons = {
    Clear: forwardRef((prop, ref) => <Clear {...prop} ref={ref} />),
    Filter: forwardRef((prop, ref) => <FilterList {...prop} ref={ref} />),
    ResetSearch: forwardRef((prop, ref) => <Clear {...prop} ref={ref} />),
    Search: forwardRef((prop, ref) => <Search {...prop} ref={ref} />),
    SortArrow: forwardRef((prop, ref) => <ArrowDownward {...prop} ref={ref} />),
    FirstPage: forwardRef((prop, ref) => <FirstPage {...prop} ref={ref} />),
    LastPage: forwardRef((prop, ref) => <LastPage {...prop} ref={ref} />),
    NextPage: forwardRef((prop, ref) => <ChevronRight {...prop} ref={ref} />),
    PreviousPage: forwardRef((prop, ref) => (
      <ChevronLeft {...prop} ref={ref} />
    )),
    Export: forwardRef((prop, ref) => <SaveAlt {...prop} ref={ref} />),
  };

  const checkSelectedUnpaused = () => {
    if (stores.length === 0) return true;
    return stores
      .filter((store) => selectedStores.includes(store.store_number))
      .every((store) => !store.is_paused);
  };
  const checkSelectedPaused = () => {
    if (stores.length === 0) return true;
    return stores
      .filter((store) => selectedStores.includes(store.store_number))
      .every((store) => store.is_paused);
  };
  const CustomIconWithText = (p) => {
    const { icon: Icon, text } = p;
    return (
      <Box display="flex" alignItems="center">
        <Icon color="action" fontSize="large" />
        <Typography variant="body1">{text}</Typography>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        icons={tableIcons}
        title="Stores"
        data={stores}
        columns={columns}
        options={{
          filtering: true,
          exportButton: true,
          exportAllData: true,
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100, 200, 500],
          selection: true,
          toolbarButtonAlignment: 'left',
          rowStyle: (data) => ({
            fontSize: 'small',
            backgroundColor: data.is_paused ? '#f0f0f0' : '',
          }),
        }}
        onSelectionChange={(rows) => {
          setSelectedStores(rows.map((row) => row.store_number));
        }}
        actions={[
          {
            icon: () => (
              <CustomIconWithText
                icon={PauseCircleOutline}
                text="Pause selected store(s)"
              />
            ),
            iconProps: { color: 'warning', fontSize: 'large' },
            tooltip: 'Pause',
            hidden: checkSelectedPaused(),
            position: 'toolbarOnSelect',
            onClick: (event, tableData) => {
              pauseStores(tableData.map((row) => row.store_number));
            },
          },
          {
            icon: () => (
              <CustomIconWithText
                icon={PlayCircleOutline}
                text="Unpause selected store(s)"
              />
            ),
            iconProps: { color: 'action', fontSize: 'large' },
            tooltip: 'Unpause',
            hidden: checkSelectedUnpaused(),
            position: 'toolbarOnSelect',
            onClick: (event, tableData) => {
              unpauseStores(tableData.map((row) => row.store_number));
            },
          },
        ]}
      />
    </ThemeProvider>
  );
}

StoresTable.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pauseStores: PropTypes.func.isRequired,
  unpauseStores: PropTypes.func.isRequired,
};
