import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import UserService from '../users/Users.api';
import LoggingService from '../shared/Logging.api';

export const NON_ADMIN_RESPONSE_CODE = 403;

const defaultState = {
  authenticated: false,
  userIsLoading: false,
  challenges: [],
  mailId: null,
  loginId: null,
  loginError: null,
  adminUsers: [],
};

function userReducer(state, action) {
  switch (action.type) {
    case 'setUserIsLoading':
      return {
        ...state,
        userIsLoading: action.userIsLoading,
      };
    case 'signedIn':
      return {
        ...state,
        authenticated: true,
        userIsLoading: false,
      };
    case 'signedOut':
      return {
        ...state,
        authenticated: false,
        userIsLoading: true,
      };
    case 'populateWMUser':
      return {
        ...state,
        user: action.userData,
        displayName: action.userData.displayName.substring(
          0,
          action.userData.displayName.indexOf(' - ') === -1
            ? action.userData.displayName.length
            : action.userData.displayName.indexOf(' - ')
        ),
      };
    case 'setLoginError':
      return {
        ...state,
        loginError: action.loginError,
        userIsLoading: false,
      };
    default:
      if (state == null) {
        console.warn('User context should never be null!', { state, action }); // eslint-disable-line no-console
      }
      return state;
  }
}

export function signInPingFed(dispatch) {
  return async function signInAction(wmAuth) {
    dispatch({
      type: 'setUserIsLoading',
      userIsLoading: true,
    });
    try {
      const wmUser = await UserService.getWalmartUser(wmAuth);

      dispatch({
        type: 'populateWMUser',
        userData: wmUser.additional,
      });
      dispatch({
        type: 'signedIn',
      });

      LoggingService.setCurrentUser(wmUser);
      return wmUser;
    } catch (ex) {
      // Navigate to the unauthorized page if not an Admin
      if (ex.response.status === NON_ADMIN_RESPONSE_CODE) {
        window.location.replace('/unauthorized');
      }

      // `window.location.replace` doesn't stop execution - the code below will still run
      if (!wmAuth) {
        // getting in here is not actually an "error", per say. It just happens on inital load because no there has not yet
        // occurred any login attempt to get an auth code from. So we just want to return an empty object and not show any error.
        dispatch({
          type: 'setUserIsLoading',
          userIsLoading: false,
        });
        dispatch({
          type: 'setLoginError',
          loginError: '',
        });
        return {};
      }

      dispatch({
        type: 'setLoginError',
        loginError:
          'There was an unexpected issue processing your request. Please try again.',
      });
      dispatch({
        type: 'setUserIsLoading',
        userIsLoading: false,
      });

      if (ex.response.status !== NON_ADMIN_RESPONSE_CODE) {
        LoggingService.error('Failed to log user into Admin Panel.', ex);
      }
      console.log('Failed to log user in', ex.message); // eslint-disable-line no-console
      throw ex;
    }
  };
}

export function signOut(dispatch) {
  return async function signOutAction() {
    dispatch({
      type: 'signedOut',
    });
  };
}

const actions = {
  signInPingFed,
  signOut,
};

export const UserContext = React.createContext(defaultState);

export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, defaultState);
  const boundActions = {};

  const keys = Object.keys(actions);

  keys.forEach((key) => {
    boundActions[key] = actions[key](dispatch, state);
  });

  return (
    <UserContext.Provider value={{ state, ...boundActions }}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
