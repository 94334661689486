import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography, TextField, Box } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

export default function Home() {
  const MIN_USER_SEARCH_LENGTH = 3;

  const [userSearchParam, setUserSearchParam] = useState('');
  const navigate = useNavigate();

  function userSearch(event) {
    event.preventDefault();
    if (userSearchParam.length === 0) {
      return;
    }

    if (userSearchParam.length < MIN_USER_SEARCH_LENGTH) {
      // eslint-disable-next-line no-alert
      alert(
        `Please enter at least ${MIN_USER_SEARCH_LENGTH} characters to search for a user.`
      );
      return;
    }
    navigate(`/users?search=${userSearchParam}`);
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: '100vh',
        marginTop: 20,
        alignItems: 'center',
        justify: 'center',
      }}
    >
      <Box
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
        }}
      >
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Users
        </Typography>
        <form
          onSubmit={userSearch}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            variant="button"
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            Search for a name, platform id, or email.
          </Typography>
          <TextField
            id="name"
            label="Search..."
            placeholder="Search..."
            variant="outlined"
            value={userSearchParam}
            onChange={(event) => setUserSearchParam(event.target.value)}
            style={{
              margin: '2vh 0',
              width: '100%',
            }}
          />
          <Button
            type="submit"
            variant="contained"
            style={{
              color: 'primary',
              width: '100%',
            }}
          >
            <PersonSearchIcon />
            <Typography variant="button" style={{ marginLeft: 10 }}>
              Submit
            </Typography>
          </Button>
        </form>
      </Box>
    </Grid>
  );
}
