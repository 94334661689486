import axios from 'axios';
import config from '../config';

export default {
  setToken,
  removeToken,
  get,
  postJson,
  put,
  deleteCall,
  postFormData,
  cancel,
  patch,
};

const { CancelToken } = axios;
let uploadCancelSource = CancelToken.source();

const { URL_BASE } = config;

const BASE_OPTIONS = {
  headers: {
    'access-control-allow-credentials': true,
    'access-control-allow-origin': '*',
    'cache-control': 'no-cache',
    'content-type': 'application/json',
  },
  withCredentials: true,
};

function setToken(token) {
  BASE_OPTIONS.headers.Authorization = `Bearer ${token}`;
}

function removeToken() {
  BASE_OPTIONS.headers.Authorization = null;
}

function get(path, opts) {
  const options = applyOptions(opts);
  const url = `${URL_BASE}${path}`;
  return axios.get(url, options);
}

function postJson(path, data, opts) {
  const options = applyOptions(opts);
  const url = `${URL_BASE}${path}`;
  return axios.post(url, JSON.stringify(data), options);
}

function put(path, data, opts) {
  const options = applyOptions(opts);
  const url = `${URL_BASE}${path}`;
  return axios.put(url, JSON.stringify(data), options);
}

function patch(path, data, opts) {
  const options = applyOptions(opts);
  const url = `${URL_BASE}${path}`;
  return axios.patch(url, JSON.stringify(data), options);
}

function deleteCall(path, opts) {
  const options = applyOptions(opts);
  const url = `${URL_BASE}${path}`;
  return axios.delete(url, options);
}

function applyOptions(options = {}) {
  return { ...BASE_OPTIONS, ...options };
}

async function postFormData(path, formData) {
  const url = `${URL_BASE}${path}`;
  return axios({
    method: 'post',
    url,
    data: formData,
    cancelToken: uploadCancelSource.token,
  }).then((response) => {
    return response.data.url;
  });
}

function cancel() {
  uploadCancelSource.cancel();
  uploadCancelSource = CancelToken.source();
}
