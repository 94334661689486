import React, { useContext } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';
import Home from './Home.page';
import Users from '../users/Users.page';
import Stores from '../stores/Stores.page';
import User from '../users/UserDetails.page';
import Priorities from '../priorities/Priorities.page';
import LoginPage from '../auth/Login.page';
import { UserContext } from '../auth/User.context';
import Unauthorized from '../auth/Unauthorized.page';
import SpinningSpark from '../shared/components/SpinningSpark';
import StoreDetails from '../stores/StoreDetails.page';
import POTD from '../potd/POTD.page';
import POTDCreate from '../potd/POTDCreate.page';
import POTDCandidates from '../potd/POTDCandidates.page';
import POTDEdit from '../potd/POTDEdit.page';

export default function Layout() {
  const { state } = useContext(UserContext);
  return (
    <Box sx={{ display: 'flex' }}>
      {(() => {
        if (state && state.authenticated && !state.userIsLoading)
          return (
            <>
              <DrawerMenu />
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Container maxWidth="xl">
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/users" element={<Users />} />
                    <Route exact path="/stores" element={<Stores />} />
                    <Route
                      exact
                      path="/stores/:store_id"
                      element={<StoreDetails />}
                    />
                    <Route
                      exact
                      path="/users/:platform_id"
                      element={<User />}
                    />
                    <Route exact path="/priorities" element={<Priorities />} />
                    <Route exact path="/potd" element={<POTD />} />
                    <Route
                      exact
                      path="/potd/candidates"
                      element={<POTDCandidates />}
                    />
                    <Route
                      exact
                      path="/potd/schedule"
                      element={<POTDCreate />}
                    />
                    <Route exact path="/potd/update" element={<POTDEdit />} />
                  </Routes>
                </Container>
              </Box>
            </>
          );
        if (!state.authenticated && !state.userIsLoading)
          return (
            <Routes>
              <Route path="/*" element={<LoginPage />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
            </Routes>
          );
        return (
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid>
              <SpinningSpark height="50" />
            </Grid>
          </Grid>
        );
      })()}
    </Box>
  );
}
